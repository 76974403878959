<template>
  <div class="password-checker">
    <div :class="{ rule: true, valid: hasCorrectLength }">
      <i class="icon-check true"></i>
      <i class="icon-close false"></i>
      Length between 8 and 64 characters
    </div>
    <div :class="{ rule: true, valid: hasLowercase }">
      <i class="icon-check true"></i>
      <i class="icon-close false"></i>
      Contains a lowercase letter
    </div>
    <div :class="{ rule: true, valid: hasUppercase }">
      <i class="icon-check true"></i>
      <i class="icon-close false"></i>
      Contains an uppercase letter
    </div>
    <div :class="{ rule: true, valid: hasDigit }">
      <i class="icon-check true"></i>
      <i class="icon-close false"></i>
      Contains a digit
    </div>
    <div :class="{ rule: true, valid: hasSpecial }">
      <i class="icon-check true"></i>
      <i class="icon-close false"></i>
      Contains a special character
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  password: {
    type: String,
    required: true
  }
})

// Regex patterns
const lowercaseRegex = /\p{Ll}/u
const uppercaseRegex = /\p{Lu}/u
const digitRegex = /\d/
const specialRegex = /[!@#$%^&*()_\-+\[\]\{\};:,<.>?~]/

const hasCorrectLength = computed(() => props.password.length >= 8 && props.password.length <= 64)
const hasLowercase = computed(() => lowercaseRegex.test(props.password))
const hasUppercase = computed(() => uppercaseRegex.test(props.password))
const hasDigit = computed(() => digitRegex.test(props.password))
const hasSpecial = computed(() => specialRegex.test(props.password))
</script>

<style scoped>
.password-checker {
  margin: 0 auto;
  font-family: sans-serif;
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  width: 100%;
}

.password-checker .rule {
  position: relative;
  color: var(--un-text-color-gray);
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  transition: color 1s ease;
}


.password-checker .rule.valid {
  color: var(--un-text-color-firm);
}
.password-checker .rule i{
  flex-shrink: 0;
  width: 10px;
  height: 10px;
}
.password-checker .rule i.true {
  display: none;
}

.password-checker .rule i.false {
  display: inline-block;
  border-radius: 50%;  
}

.password-checker .rule.valid i.true {
  display: inline-block;
}

.password-checker .rule.valid i.false {
  display: none;
}
</style>