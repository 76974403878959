<template>

  <div class="pb journal_page">

    <div class="journal journal_list">




        <UiBread
          :list="[
            {name: $t('journal_view_link_text'), link: '/journal'},
            {name: category},
          ]"
          position="left"
          /> 


          
        <div class="latests small_boxes" v-if="journalCategoryData?.items_blog?.length">

          <div class="small_boxes">
            

            <div class="pj">
              <h1 class="ptl ui header">{{ category }} </h1>
              <div class="list">
                <BlogBox 
                  v-for="(item,ind) in journalCategoryData.items_blog" 
                  :class="'jbox box_small'" 
                  :key="ind"
                  :data="item"      
                  />
              </div>
            </div>
 
                
          </div>

          <ClientOnly>
            <InfinityScroll 
              :is-loading="journalCategoryDataStatus === 'loading'"
              ref="elInfinityScroll"/>
          </ClientOnly>

        </div>
      </div>
 


  </div>

</template>

<script setup lang="ts">

import type { Blog }  from "@/types/blog"

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';

const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $tagsUtil, $ga} = useNuxtApp()
const route = useRoute();
const elInfinityScroll = ref(null)
const category = route.params['var']
const blogs_count = ref(0)

const loadData = async function() {     
  const response = await $api.getJournalCategoryList(
    start.value,
    limit.value,
    route.params['var']
  )

  if(response && start.value == 0){
    blogs_count.value = response.blogs_count
  }

  return response
}


interface JournalData {
  items_blog: Blog[];
}

const { status: journalCategoryDataStatus,  data: journalCategoryData } = await useLazyAsyncData('journalCategoryData', async () => await loadData() as JournalData)


useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!journalCategoryDataStatus.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


watchArray([start], async ([new_start], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    journalCategoryData.value = {items_blog:[]};
    isEnded.value = false;
  }

  journalCategoryDataStatus.value = true;

  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;
  
  if(dt.items_blog.length){
    journalCategoryData.value.items_blog = [...journalCategoryData.value.items_blog, ...dt.items_blog]  
  }

  journalCategoryDataStatus.value = false;
}, {deep: true})


//useHead(useNuxtApp().$head.getJournalsCategory(route.params['var'],blogs_count.value));

watchArray([route, journalCategoryData], async ([new_route, new_journalCategoryData], added, removed) => {
  
  useHead(useNuxtApp().$head.getJournalsCategory(route.params['var'],blogs_count.value));

}, {deep: true})


onBeforeUnmount(() => {
  journalCategoryData.value = null;
})

</script>

<style scoped>
 

</style>


<style scoped>

.ptl{
  text-transform: capitalize;
  margin-bottom: 1rem;
  margin-top: 1rem;

  width: 100%;
  /* text-transform: uppercase; */
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.pj{  
  display: flex;
  flex-wrap: wrap;
  container: pj / inline-size;

}

.list{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.list:deep(.jbox.box_small){
  width: 100%;
}

</style>