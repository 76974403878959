<template>
  <div class="pbf">

     <UserTabFollowing
      :inurl="'/users/' + props.id + '/following'"      
      />

  </div>
</template>

<script setup lang="ts">

import type { User } from '@/types/user'
 
const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();


</script>

<style scoped>
 

</style>

