<template>

    <Strains2
        :page-category-id="categoryId"
        :page-category-val="categoryVal"
    />

</template>

<script setup lang="ts">

import { formatStrainsLink } from '@/types/other';

import Strains2 from '~/pages/strains2/index.vue';
import {strains} from '~/lang/strains/en.js'

const route = useRoute();
const categoryId = ref(route.params['category']);
const categoryVal = ref(route.params['val']);

</script>

<style scoped>
 
</style>
