<template>

  <div v-if="termsData" class="pb text-style-info">

    <div class="html_into" v-html="termsData.body"></div>

  </div>

</template>

<script setup lang="ts">

const { $api } = useNuxtApp();

const { data: termsData } = await useLazyAsyncData('termsData', async () => await $api.getStaticPage('terms'))

useHead(useNuxtApp().$head.getStaticPage());
 
</script>

<style scoped>

.text-style-info{
  text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}
 
</style>