<template>




  <div class="pb problem_item" v-if="problem_data">
 
    <UiBread
      :list="bread"
      :position="'left'"
      /> 

      <br>

 
          
    <h1>{{ problem_data.title ? problem_data.title : problem_data.subtitle }}</h1>

    <QuestionView 
      :data="problem_data" 
      @reply="showFormSolution = true"
      />

    <QuestionSolutionForm
      v-if="showFormSolution && !problem_data.is_resolved && useAuth().isAuth() && useAuth().getId() != problem_data.item_user.id"
      :question-id="problem_data.id"
      @created="createdSolution($event)"   
      />

    <AssistantWidget 
      v-if="useAuth().getAccess('view_extra')"
      :content="problem_data.id"
      :type="'problem'"
      />

    <div class="cpm"></div>

    <div class="solutions">
      <template
        v-for="solution in problem_data.items_problem_solution"
        >
        <QuestionSolution 
          :solution="solution"  
          :question="problem_data"  
          :is-liked="problem_data?.addon?.like_solutions.indexOf(solution.id) >= 0 ? 1 : 0 "
          @select="selectSolution"
          />
      </template>
    </div>



    <QuestionSimilar
      :problemData="problem_data"
      />
          
  </div>
</template>

<script setup>

 
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const {$api, $tagsUtil, $ga, $popup} = useNuxtApp()
var link = ref(route.params['id']);
var isEnded = ref(true);
var showFormSolution = ref(true);


const getNumber = function(str){
  var id = str.split('-')[0];
  if (!isNaN(id) && id.trim() !== '') {
    return Number(id);  
  } else {
    return null;  
  }
}

if(getNumber(link.value) === null){
  // if(import.meta.server){
  //   throw createError({ statusCode: 404, message: 'Page not found' });
  // }else{
  //   throw createError({ statusCode: 404, message: 'Page not found' });
  // }
  throw createError({ statusCode: 404, message: 'Page not found' });
}
  

const selectSolution = async function(solution){
  console.log(solution);
  console.log(problem_data.value.items_problem_solution);
  problem_data.value.items_problem_solution.forEach(item => {
    item.is_resolve = false;
  })

  problem_data.value.is_resolved = 1;
  solution.is_resolve = true;
  sendSelectSolution(problem_data.value.id, solution.id);
  
}

const sendSelectSolution = (prodblem_id, solution_id) => {
  $api.patchQuestionSolution(prodblem_id, solution_id, {is_resolve: 1})
  .then((res) => {          
    console.log('selected');       
    console.log(res);            
    $popup.success('Success');
  })
  .catch(function (error) { 
    console.log('select error');       
    console.log(error);     
    $popup.error('Error');           
  });  
}

const createdSolution = async function(solution){
  console.log('created');
  showFormSolution.value = false;
 
  isEnded.value = false; 
  is_loading.value = true;
  var dt = await loadData(getNumber(link.value));
  isEnded.value = true;  

  problem_data.value.items_problem_solution = dt.items_problem_solution;
  is_loading.value = false;
}


const bread = computed(() => {
  return [
    {name: t('grow_questions_view_title'), link: '/grow-questions'},
    {name: problem_data.value.title ? problem_data.value.title : problem_data.value.subtitle, link: problem_data.value.link},
  ]
})

const loadData = async function(id) {     
  const response = await $api.getQuestion(id)  
  return response;  
}

const { pending: is_loading,  data: problem_data } = await useLazyAsyncData('problem_data_' + link.value, async () => await loadData(getNumber(link.value)))



watch(problem_data, async ( new_problem_data) => {
  useHead(useNuxtApp().$head.getGrowQuestion(problem_data.value));
})

watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  useHead(useNuxtApp().$head.getGrowQuestion(problem_data.value));
}, {deep: true})

useHead(useNuxtApp().$head.getGrowQuestion(problem_data.value));

  

 
</script>

<style scoped>

h1{
  margin-bottom: 1rem;
}
.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.solutions{
  padding-left: 20px;
  margin-top: 1rem;
}

</style>
