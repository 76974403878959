<template>

  <div class="pb" v-if="diaryData && allowEdit">
    <h1>
      {{ $t('create_diary_view_title') }}
    </h1>

    <DiaryEditProgress
      :steps="progressSteps"
      :states="progressStates"
    />



    <h3 ref="elDiarySignup" v-if="!useAuth().isAuth()">
      {{ $t('signup_view_title') }}
    </h3>

    <GeneralSignupShort
      v-if="!useAuth().isAuth()"
      :signup-start-auth="signupStartAuth"
      ref="elSignupForm"
    />

    <template v-if="isLoadedPresaver">
      <br>
      <br>
      <UiMessage
        type="green"
        :button-name="$t('create_week_view_presave_clear')"
        :button-type="'primary'"
        @click-button="presaveClear"
      >
        <b>{{ $t('create_week_view_loaded_from_previous_edit') }}</b>
        <div>
          <span>{{ $t('create_week_view_presave_text1') }}</span>
          {{ $t('create_week_view_presave_text2') }}
        </div>
      </UiMessage>
    </template>

    <h3 ref="elDiaryName">
      {{ $t('create_diary_view_diary_name_title') }} {{ useAuth().getName() }}
    </h3>

    <p>
      {{ $t('create_diary_view_diary_name_subtitle') }}
    </p>

    <div class="head_diary">
      <div class="inf">
        <div>
          <label for="">{{ $t('create_diary_view_diary_name') }}</label>
        </div>

        <input type="text" v-model="diaryData.name">

        <div class="warn">
          {{ $t('create_diary_view_diary_only_single_strain') }}
        </div>
      </div>

      <div v-if="diaryData.id && diaryData.id !== 'new'" class="ava">
        <img class="img" data-not-lazy :src="diaryData.avatar_small" alt="Cover">
        <DiaryEditUploadCover
          :diary_id="diaryData.id"
          @changed="changedCover"
          @uploaded="uploadedCover"
        />
      </div>
    </div>

    <!-- Seeds -->
    <h3 ref="elDiarySeeds">
      {{ $t('create_diary_view_strains_title') }}
    </h3>

    <p>
      {{ $t('create_diary_view_strains_subtitle') }}
    </p>
    <br>

    <div class="items">
      <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.Seed)" :key="item_key">

        <DiaryEditUniversalViewBox
          v-if="!item.is_remove"
          :item="item"
          :category="ProductCategoryType.Seed"
          @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.Seed)"
          @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.Seed)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.Seed).length"
        :category="ProductCategoryType.Seed"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Seed)"
      />      
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ProductCategoryType.Seed"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Seed)"
      />

    </div>

    <!-- Room Type -->
    <h3 ref="elDiarySetup">
      {{ $t('universal_room_type_title') }}
    </h3>

    <DiaryEditSelectRadioImgBox
      @choose="useDiary().changeTypeRoom(diaryData, $event)"
      :list="DiaryTypeRoomInfo"
      :selected="diaryData.type_room"
    />

    <!-- Grow Tent -->

    <template v-if="diaryData.type_room === DiaryTypeRoom.Indoor || diaryData.type_room === DiaryTypeRoom.Greenhouse">
      <h3>
        {{ $t('universal_tent_title') }}
      </h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.Tent)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.Tent"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.Tent)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.Tent)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.Tent).length"
          :category="ProductCategoryType.Tent"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Tent)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.Tent"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Tent)"
        />
      </div>
    </template>


    <!-- Vegetation Lights -->
    <template v-if="diaryData.type_room === DiaryTypeRoom.Indoor || diaryData.type_room === DiaryTypeRoom.Greenhouse">
      <h3>
        {{ $t('universal_vegetation_lights_title') }}
      </h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.Lamp, ProductLampFaza.Vegetation)" :key="item_key">   
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.Lamp"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.Tent)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.Tent)"
            @clone="useDiary().cloneEquip(diaryData, item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.Lamp, ProductLampFaza.Vegetation).length"
          :category="ProductCategoryType.Lamp"
          :faza="ProductLampFaza.Vegetation"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Tent)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.Lamp"
          :faza="ProductLampFaza.Vegetation"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Tent)"
        />
      </div>
    </template>

    <!-- Flowering Lights -->
    <template v-if="diaryData.type_room === DiaryTypeRoom.Indoor || diaryData.type_room === DiaryTypeRoom.Greenhouse">
      <h3>
        {{ $t('universal_flowering_lights_title') }}
      </h3>

      <div class="clone">
        <span class="clone_btn" @click="useDiary().copyVegetationLamp(diaryData)">
          <i class="icon-clone"></i> {{ $t('create_diary_lamp_clone_vegetation') }}
        </span>
      </div>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.Lamp, ProductLampFaza.Flowering)" :key="item_key">          
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.Lamp"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.Lamp)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.Lamp)"
            @clone="useDiary().cloneEquip(diaryData, item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.Lamp, ProductLampFaza.Flowering).length"
          :category="ProductCategoryType.Lamp"
          :faza="ProductLampFaza.Flowering"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Lamp)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.Lamp"
          :faza="ProductLampFaza.Flowering"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Lamp)"
        />
      </div>
    </template>

    <!-- Ventilation Fans-->
    <template v-if="diaryData.type_room === DiaryTypeRoom.Indoor || diaryData.type_room === DiaryTypeRoom.Greenhouse">
      <h3>{{ $t('equip_ventilation_fans') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.VentilationFan)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.VentilationFan"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.VentilationFan)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.VentilationFan)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.VentilationFan).length"
          :category="ProductCategoryType.VentilationFan"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.VentilationFan)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.VentilationFan"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.VentilationFan)"
        />
      </div>
    </template>

    <!-- Air Filters-->
    <template v-if="diaryData.type_room === DiaryTypeRoom.Indoor || diaryData.type_room === DiaryTypeRoom.Greenhouse">
      <h3>{{ $t('equip_air_filter') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.AirFilter)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.AirFilter"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.AirFilter)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.AirFilter)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.AirFilter).length"
          :category="ProductCategoryType.AirFilter"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.AirFilter)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.AirFilter"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.AirFilter)"
        />
      </div>
    </template>

    <!-- Air Conditioners-->
    <template v-if="diaryData.type_room === DiaryTypeRoom.Indoor || diaryData.type_room === DiaryTypeRoom.Greenhouse">
      <h3>{{ $t('equip_air_conditioner') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.AirConditioner)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.AirConditioner"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.AirConditioner)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.AirConditioner)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.AirConditioner).length"
          :category="ProductCategoryType.AirConditioner"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.AirConditioner)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.AirConditioner"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.AirConditioner)"
          />
      </div>
    </template>

    <!-- watering -->
    <h3>
      {{ $t('universal_watering_title') }}
    </h3>

    <DiaryEditSelectRadioImgBox
      @choose="useDiary().changeTypeWatering(diaryData, $event)"
      :list="DiaryTypeWateringInfo"
      :selected="diaryData.type_watering"
    />

    <!-- Drip Systems -->
    <template v-if="diaryData.type_watering === DiaryTypeWatering.Drip">
      <h3>{{ $t('equip_drip_systems') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.DripSystem)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.DripSystem"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.DripSystem)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.DripSystem)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.DripSystem).length"
          :category="ProductCategoryType.DripSystem"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.DripSystem)"
        />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ProductCategoryType.DripSystem"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.DripSystem)"
      />
      </div>
    </template>

    <!-- Hydroponic Systems -->
    <template v-if="diaryData.type_watering === DiaryTypeWatering.Hydroponic">
      <h3>{{ $t('equip_hydroponic_systems') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.HydroponicSystem)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.HydroponicSystem"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.HydroponicSystem)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.HydroponicSystem)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.HydroponicSystem).length"
          :category="ProductCategoryType.HydroponicSystem"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.HydroponicSystem)"
        />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ProductCategoryType.HydroponicSystem"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.HydroponicSystem)"
      />
      </div>
    </template>

    <!-- Controllers-->
    <h3>{{ $t('equip_controllers') }}</h3>

    <div class="items">
      <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.Controller)" :key="item_key">
        <DiaryEditUniversalViewBox
          :item="item"
          :category="ProductCategoryType.Controller"
          v-if="!item.is_remove"
          @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.Controller)"
          @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.Controller)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.Controller).length"
        :category="ProductCategoryType.Controller"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Controller)"
      />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ProductCategoryType.Controller"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.Controller)"
      />
    </div>

    <!-- medium -->
    <h3>
      {{ $t('universal_grow_medium_title') }}

      <span class="medium_used">
        {{ $t('create_diary_grow_medium_used') }}
        {{ totalMedium }}%
      </span>
    </h3>


    <div class="items">
      <template v-for="(item, item_key) in useDiary().getItemsMedium(diaryData)" :key="item_key">
        <DiaryEditUniversalViewBox
          v-if="!item.is_remove"
          :item="item"
          :total-medium="totalMedium"
          :category="ProductCategoryType.Medium"
          @remove="useDiary().deleteItemsMedium(diaryData, item)"
          @choose="useDiary().changeItemsMedium(diaryData, item, $event)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="useDiary().getItemsMedium(diaryData).length"
        :category="ProductCategoryType.Medium"
        @choose="useDiary().addItemsMedium(diaryData, $event)"
      />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ProductCategoryType.Medium"
        @choose="useDiary().addItemsMedium(diaryData, $event)"
      />
    </div>

    <!-- CO2 Generaots -->
    <h3>{{ $t('equip_co2_generators') }}</h3>

    <div class="items">
      <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.CO2Generator)" :key="item_key">
        <DiaryEditUniversalViewBox
          :item="item"
          :category="ProductCategoryType.CO2Generator"
          v-if="!item.is_remove"
          @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.CO2Generator)"
          @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.CO2Generator)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.CO2Generator).length"
        :category="ProductCategoryType.CO2Generator"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.CO2Generator)"
      />  
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ProductCategoryType.CO2Generator"
        @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.CO2Generator)"
      />
    </div>


    <!-- Grow Boxes-->
    <template v-if="diaryData.type_room === 1">
      <h3>{{ $t('equip_grow_boxes') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in useDiary().getItemsEquip(diaryData, ProductCategoryType.GrowBox)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ProductCategoryType.GrowBox"
            v-if="!item.is_remove"
            @remove="useDiary().deleteItemsEquip(diaryData, item, ProductCategoryType.GrowBox)"
            @choose="useDiary().changeItemsEquip(diaryData, item, $event, ProductCategoryType.GrowBox)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="useDiary().getItemsEquip(diaryData, ProductCategoryType.GrowBox).length"
          :category="ProductCategoryType.GrowBox"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.GrowBox)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ProductCategoryType.GrowBox"
          @choose="useDiary().addItemsEquip(diaryData, $event, ProductCategoryType.GrowBox)"
        />
      </div>
    </template>

    <br>

    <UiButtons>
      <UiButton type="primary" :is-loading="isLoadingSave" :name="$t('universal_button_save')" @click="save"/>
      <UiButton v-if="diaryData.id" :name="$t('universal_button_cancel')" @click="cancel"/>
    </UiButtons>
  </div>
  <div v-else-if="diaryData?.id && !allowEdit">
    <UiNoAccess />
  </div>
</template>




<script setup lang="ts">

import type { DiaryEquip, Diary, DiaryMedium }  from "@/types/diary";
import { createDiary, DiaryTypeRoom, DiaryTypeWatering, DiaryTypeRoomInfo, DiaryTypeWateringInfo }  from "@/types/diary";
import { ProductCategoryType, ProductLampFaza }  from "@/types/product";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $popup, $ga, $scrollto, $presaver} = useNuxtApp()
const isLoadingSave = ref(false);
const elDiaryName = ref(null);
const elDiarySeeds = ref(null);
const elDiarySetup = ref(null);
const elDiarySignup = ref(null);
const signupStartAuth = ref(false);
const elSignupForm = ref(null);
const isLoadedPresaver = ref(false);
const link = ref<string>(typeof route.params.id === 'string' ? route.params.id : route.params.id[0]);
const id = ref<number>(parseInt(link.value));
// const presaver = $presaver(id.value, 'diary');
const isWarnedEmptySeedProducts = ref(false);
const isNewDiary = computed(() => link.value === 'new');

const def = ref({
  id: id.value,
  items_equip: [],
  items_lamp_flo: [],
  items_lamp_veg: [],
  items_medium: [],
  name: null,
  type_room: 1,
  type_watering: null
});

const progressSteps = ref([
  {
    id: 'name',
    name: t('create_diary_view_navigation_dairy_name'),
    hintGood: t('create_diary_view_navigation_way_to_go'),
    hintNormal: t('create_diary_view_navigation_give_it'),
    hintBad: t('create_diary_view_navigation_give_it'),
    el: elDiaryName,
  },
  {
    id: 'strains',
    name: t('create_diary_view_navigation_strains'),
    hintGood: t('create_diary_view_navigation_terrific'),
    hintNormal: t('create_diary_view_navigation_what_strains'),
    hintBad: t('create_diary_view_navigation_what_strains'),
    el: elDiarySeeds,
  },
  {
    id: 'setup',
    name: t('create_diary_view_navigation_setup'),
    hintGood: t('create_diary_view_navigation_you_rock'),
    hintNormal: t('create_diary_view_navigation_good_any_more_info'),
    hintBad: t('create_diary_view_navigation_lets_talk_about'),
    el: elDiarySetup,
  },
]);

const progressStates = computed(() => useDiary().calculateProgress(diaryData.value));

const totalMedium = computed(() => useDiary().calculateTotalMedium(diaryData.value));

 
const cancel = function() {
  var url = isNewDiary.value ? '/explore' : '/diaries/' + id.value;

  if (isNewDiary.value) {
    var url = '/explore';
  } else {
    var url = '/diaries/' + diaryData.value.link;
  }

  router.push({
    path: url
  })
}
 

const isValid = function() {

  if (!diaryData.value.name) {
    $scrollto({
      el: elDiaryName,
      offset: 130,
    })

    $popup.error('Empty diary name');
    return false;
  }

  if (!diaryData.value.type_room) {
    $scrollto({
      el: elDiarySetup,
      offset: 130,
    })

    $popup.error('Empty room type');
    return false;
  }

  if (!useDiary().isValidSeedList(diaryData.value)) {
    isWarnedEmptySeedProducts.value = true;
    $scrollto({ el: elDiarySeeds, offset: 130 })
    $popup.error('Please fill in the strain names for breeders. Otherwise, they will be saved with the name “Custom” when you save.');
    return false;
  }

  return true;
}

const save = async function() {

  isLoadingSave.value = true;

  if (!useAuth().isAuth()) {
    $scrollto({
      el: elDiarySignup,
      offset: 130,
    })

    if(!elSignupForm.value){
      isLoadingSave.value = false;
      return false;
    }

    var signupRes = await elSignupForm.value.signUpHandler()

    if (!signupRes) {
      // console.log('not ready');

      isLoadingSave.value = false;
      return false;
    } else {
      // console.log('ready');
    }
  }


  if(isWarnedEmptySeedProducts.value){
    useDiary().fillEmptyProductsName(diaryData.value);
  }


  if (!isValid()) {
    isLoadingSave.value = false;
    return false;
  }

  const copyDiaryData = JSON.parse(JSON.stringify(diaryData.value))
  copyDiaryData.items_equip = copyDiaryData.items_equip.filter((item: DiaryEquip) => item)

  if (!copyDiaryData.id) {
    $api.postDiary(copyDiaryData)
      .then((res) => {
        var data = res.data.value.data;
        $popup.success(t('create_diary_view_message_success'));
        $ga.addDiary();
        isLoadingSave.value = false;
        // presaver.clearType('diary');
        router.push('/diaries/' + data.link);
      })
      .catch(function (error) {
        isLoadingSave.value = false;
        $popup.error(t('create_diary_view_error_failed'));
      });
  } else {
    $api.putDiary(copyDiaryData.id, copyDiaryData)
      .then((res) => {
        var data = res.data.value.data;
        $popup.success(t('create_diary_view_message_success'));
        isLoadingSave.value = false;
        // presaver.clearType('diary');
        router.push('/diaries/' + data.link);
        // presaveClear();
      })
      .catch(function (error) {
        console.log(error);
        $popup.error(t('create_diary_view_error_failed'));
        isLoadingSave.value = false;
      });
  }
}

const changedCover = function(uploading_id: string) {
  // console.log('loading', uploading_id)
}

const uploadedCover = function(data: string) {
  diaryData.value.avatar_small = data;
}

const presaveClear = async function() {
//   isLoadedPresaver.value = false;
//   presaver.clearType('diary');
//   diaryData.value = await loadData(id.value);
}

const allowEdit = computed(() => {
  if (!diaryData.value.id)
    return true;

  if (diaryData.value.item_user.id == useAuth().getId())
    return true;

  return false;
});

const loadData = async function(id: number) {
  // if (presaver.exists()) {
  //   isLoadedPresaver.value = true;
  //   return presaver.get();
  // }

  if (isNewDiary.value) {
    return createDiary();
  }

  return await $api.getDiary(id)
}


const { status: statusDiaryData,  data: diaryData } = await useLazyAsyncData('editDiaryData' + id.value, async () => await loadData(id.value))

watch(() => diaryData, (new_diaryData, old_diaryData) => {
  if (new_diaryData.value?.id) {
    // console.log('presaver watch');
    // presaver.set(new_diaryData.value);
  }
}, {deep: true});

onMounted(() => {
  // if (presaver.exists()) {
  //   isLoadedPresaver.value = true;
  //   diaryData.value = presaver.get();
  // }
});

</script>










<style scoped>

h1,
h2,
h3{
  margin-top: 1.3rem;
  margin-bottom: 1rem;
}

.medium_used {
  color: var(--un-text-color-firm);
}

.warn {
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  background-color: #FFF8DB;
  color: #B58105;
  box-shadow: 0px 0px 0px 1px #b58105 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
.items{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* align-items: flex-start; */
}
.head_diary{
  display: flex;
  align-items: center;
  gap:1rem;
}
.head_diary .inf{
  width: 100%;
}
.head_diary .ava{
  width: 180px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.head_diary .ava .img{
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 10px;
}
.clone{
  margin-bottom: 0.5rem;
}
.clone_btn{
  cursor: pointer;
}
</style>