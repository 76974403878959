<template>


  <div class="info"  v-if="props.data">
     
    <!-- <BrandDesc
      :info="props.data.info"
      :nm="props.data.name"
      :nmalt="props.data.name"
      :avatar="props.data.avatar_small"
      :avatar-ratio="'free'"
      :items_photo="props.data.items_photo"
      />  -->
      

    <!-- <BrandProductSetup v-if="props.data.item_product_setup"
      :type="seed"
      :list="props.data.item_product_setup"
      /> -->
      

      <!-- <div class="cpm"></div> -->
     
    <!-- <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('breeder_tab_info_awards')"
      :showmore="$t('product_view_diaries_show_all')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'win'})"
      :cnt="props.data.cnt_reports"
      :link="$route.path + '/diaries'"
      />    -->
     
    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('strain_view_popular_diaries')"
      :showmore="$t('strain_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'top'})"
      :cnt="props.data.cnt_reports"
      :link="$route.path + '/diaries'"
      />   
    
    <BrandShorts  
      :title="'Shorts'"
      :showmore="'Show all'"
      :tag="'sd:' + props.data.id + ':' + props.data.section"
      />


    <!-- <BrandProductSeedStat v-if="props.data.item_stat"
      :list="props.data.item_stat" 
      /> -->

    <!-- <h2 class="divider-sec" v-if="props.data.items_harvest_taste?.length || props.data.items_harvest_effect.length">
      {{ $t('strain_view_subtitle_tastes_effects') }}
    </h2> -->
    
    <!-- <BrandProductSeedEffects v-if="props.data.items_harvest_taste"
      :list="props.data.items_harvest_taste" 
      :type="'taste'"
      /> -->

    <!-- <BrandProductSeedEffects v-if="props.data.items_harvest_effect"
      :list="props.data.items_harvest_effect" 
      :type="'effect'"
      /> -->

    <div class="cpm"></div>

    <BrandHybrid v-if="props.data.hybrid?.length && props.data.hybrid?.length"
      :title="$t('strain_view_subtitle_hybrids')"   
      :list="props.data.hybrid" 
      />
      
    <!-- <BrandFamilyTree v-if="props.data.family_tree?.length && props.data.family_tree[0]?.id"
      :title="$t('strain_view_subtitle_family_tree')"   
      :list="props.data.family_tree" 
      /> -->
      
       
    <BrandTreeHorizontal
      v-if="props.data.item_strain?.tree?.length && useAuth().getAccess('view_extra')"
      :title="$t('strain_view_subtitle_family_tree')"   
      :tree="props.data.item_strain?.tree" 
    />
      
    <BrandFamilyTreeHorizontal v-else-if="props.data.family_tree?.length && props.data.family_tree[0]?.id"
      :title="$t('strain_view_subtitle_family_tree')"   
      :list="props.data.family_tree" 
    />
     
     


    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('strain_view_subtitle_new_diaries')"
      :showmore="$t('strain_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'new'})"
      :cnt="props.data.item_stat.cnt_reports"
      :link="$route.path + '/diaries'"
      />
     
    <BrandHarvestItems
      :title="$t('strain_view_subtitle_latest_harvests')"
      :showmore="$t('breeder_view_show_all_harvests')"     
      :link="$route.path + '/harvests'" 
      :list="props.data.items_harvest"
      :cnt="props.data.item_stat.cnt_harvests"
      />    
     
    <BrandReviewItems
      :title="$t('strain_view_latest_reviews')"
      :showmore="$t('breeder_view_show_all_reviews')"      
      :link="$route.path + '/reviews'"  
      :list="props.data.items_review"
      :cnt="props.data.item_stat.cnt_reviews"
      />    
     
    <BrandQuestionItems
      :title="$t('breeder_view_info_grow_questions')"
      :showmore="$t('breeder_view_info_grow_questions_all')"    
      :link="'/grow-questions?q_seed=' + props.data.id"     
      :list="props.data.items_problem"
      :cnt="props.data.cnt_problem"
      />    
      
    <!-- <BrandContestItems v-if="props.data.items_contest && props.data.items_contest.length"
      :title="$t('breeder_view_info_open_contests')"
      :list="props.data.items_contest.filter(b => {return b.group_name === 'running'})"
      />    
      
    <BrandContestItems v-if="props.data.items_contest && props.data.items_contest.length"
      :title="$t('breeder_view_info_completed_contests')"
      :list="props.data.items_contest.filter(b => {return b.group_name === 'completed'})"
      /> -->


    <div class="cpm"></div>

     
    <BrandSimilarProductsBig
      :title="$t('strain_view_info_sidebar_title')"   
      :list="props.data.items_similar_product" 
      type="product"
      />
     
 

    <CommentWidget v-if="props.data.id"
      :title="$t('strain_view_subtitle_latest_comments')"
      :type="'product'"
      :content="props.data.id"
      />



  </div>
</template>


<script setup>

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const productsCurrentYear = computed(() => {
  return getProductsList(new Date().getFullYear());
})
const productsYearAgo = computed(() => {
  return getProductsList(new Date().getFullYear()-1);
})
const productsOld = computed(() => {
  return getProductsList('old');
})

const getProductsList = (year) => {
  var arr = [];      
  if(props.data.items)
    for(var item of props.data.items)
      if(year == item.group_name)
        arr.push(item);        
  return arr;
}

useHead(useNuxtApp().$head.getSeedInfo(props.data));

</script>

  

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
