<template>
  <!-- {{ selectedAvatarPreview }} -->
  <!-- {{ selectedAvatarFile }}
  {{ selectedAvatarFile.name }} -->
  <div class="avaf">
    <img class="ava" @click="avatarRandHandler" :src="avatarSrc" />
    <div class="acts">      
      <label class="btn gray btn_upload" for="avatarUpload">
        <i class="icon-upload"></i> Upload
      </label>
      <input
        type="file"
        id="avatarUpload"
        accept="image/*"
        @change="onAvatarUpload"
        style="display: none;"
      />
      <div class="btn gray btn_rnd" @click="avatarRandHandler" type="is-primary">
        <i class="icon-shuffle"></i>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  initialAvatarNum: {
    type: Number,
    default: () => Math.floor(Math.random() * 83) + 1,
  },
});

const emit = defineEmits(['update:avatar']);

const { t } = useI18n();
const { $popup } = useNuxtApp();

const avatar_num = ref(props.initialAvatarNum);
const selectedAvatarFile = ref(null);
const selectedAvatarPreview = ref(null);

const avatarSrc = computed(() => {
  if (selectedAvatarPreview.value) {
    return selectedAvatarPreview.value;
  } else {
    return '/avatars/' + avatar_num.value + '.jpg';
  }
});

const avatarRandHandler = function () {
  selectedAvatarFile.value = null;
  selectedAvatarPreview.value = null;
  avatar_num.value = Math.floor(Math.random() * 83) + 1;
  emitAvatarUpdate();
};

const onAvatarUpload = (event) => {
  const file = event?.target?.files[0];
  if (file && file.type.startsWith('image/')) {
    selectedAvatarFile.value = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      selectedAvatarPreview.value = e.target.result;
    };
    reader.readAsDataURL(file);
    emitAvatarUpdate();
    
  } else {
    // selectedAvatarFile.value = null;
    // selectedAvatarPreview.value = null;
    // $popup.error(t('signup_view_error_invalid_image'));
  }
};

const emitAvatarUpdate = () => {
  if (selectedAvatarFile.value) {
    emit('update:file', { file: selectedAvatarFile.value });
  } else {
    emit('update:num', avatar_num.value);
  }
};

onMounted(() => {
  // emitAvatarUpdate();
  avatarRandHandler();
});

// Emit avatar update when avatar number or selected file changes
watch([avatar_num, selectedAvatarFile], emitAvatarUpdate);

</script>

<style scoped>
.ava {
  max-width: 180px;
  border-radius: 100%;
  display: block;
  cursor: pointer;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.avaf {
  margin-bottom: 1rem;
  text-align: center;
}
.acts{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: -1rem;
}

.btn_rnd,
.btn_upload {
  display: block;
  z-index: 1;
  position: relative;
  width: fit-content;

}
.btn_upload {

}
.btn_rnd{    
  padding: 0.5rem 0.7rem;
}
</style>