<template>


  <SkBasicForm 
    v-if="userCommonDataStatus !== 'success'"
  />

  <div v-else-if="userCommonData?.id">
    
    <div class="modal_user_content">

      <div class="image content content_profile">
 

        <div v-if="userCommonData.id" class="ava">
          <img class="img" data-not-lazy :src="userCommonData.avatar_small" alt="Cover">
          <UserSettingsUploadCover
            :userId="userCommonData.id"
            @changed="changedCover"
            @uploaded="uploadedCover"
            />
        </div>
          

        <div class="settings_description">

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_username') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="userCommonData.name" placeholder="nick">
            </div>
          </div>


          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_email') }} 
            </div>
            <div class="field_input">
              <input type="text"  v-model="userCommonData.email" placeholder="email">
              <i class="icon at"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              Note under nickname
            </div>
            <div class="field_input">
              <input type="text" v-model="userCommonData.slogan" placeholder="Note...">
            </div>
          </div>
 
 
          <div class="row">
            <div class="label">
              <UiToggle 
                v-model="userCommonData.enable_email_notifications"
                @update:modelValue="userCommonData.enable_email_notifications = $event"
                :label="$t('profile_menu_edit_profile_checkbox_send_me')"
                />
            </div>
          </div>

          <br />
 

          <UiButton
            :name="$t('universal_button_save')"
            :type="'primary'"
            @click="saveProfile"
            :is-loading="isSaving"
            />        

 

        </div>

      </div>

    </div>
 
  

  </div>

    

</template>

<script setup lang="ts">

import type { UserSettings, User } from '@/types/user'

const { t } = useI18n();
const route = useRoute();

const router = useRouter();
const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()

interface Props {
  user: User,
  userId: number,
}

const props = defineProps<Props>();


const isSaving = ref<boolean>(false);
const oldNickName = ref<string>('');


const validate = function(){

  /* name */
  var pattern = /^_+/i;
  userCommonData.value.name = userCommonData.value.name.replace(pattern,'');
  var pattern = /_+$/i;
  userCommonData.value.name = userCommonData.value.name.replace(pattern,'');
  var re1 = new RegExp("^[a-zA-Z0-9_]+$");

  if(!re1.test(userCommonData.value.name) || userCommonData.value.name.length < 5 || userCommonData.value.name > 20){
    $popup.error(t('profile_menu_edit_profile_error_length_username'));
    return false;
  }

  // email validate
  var re = /\S+@\S+\.\S+/;
  if(!re.test(userCommonData.value.email)){
    $popup.error('Incorrect email');
    return false;
  }
 
  return true;
}

const redirectIfNewName = function(){
  if(oldNickName.value != userCommonData.value.name){
    router.push('/grower/' + userCommonData.value.name+'/settings');
  }
}

const saveProfile = function(){

  if(!validate()){
    return;
  }
  
  isSaving.value = true;  
  $api.putGrowerProfile(userCommonData.value.id, userCommonData.value)
  .then((res) => {
    isSaving.value = false;
    $popup.success(t('profile_menu_edit_profile_message_save_profile'));
    redirectIfNewName();
    useAuth().refresh();
  })
  .catch(function (error) {
    isSaving.value = false;
  });

}


const changedCover = function(uploading_id: string){  
  console.log('loading', uploading_id)
}

const uploadedCover = function(data){  
  userCommonData.value.avatar_small = data;
}


const loadData = async function(id: number) {   
  const response = await $api.getGrowerSettings(id)  
  oldNickName.value = response.name;
  return response;  
}


const { status: userCommonDataStatus,  data: userCommonData } = await useLazyAsyncData('userCommonData', async () => await loadData(props.userId) as UserSettings)



</script>



<style scoped>

.content_profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.modal_user_content .settings_description {
    display: inline-block;
    width: calc(100% - 230px);
    vertical-align: middle;
    margin-right: auto;
}

.modal_user_content .settings_avatar {
    display: inline-block;
    vertical-align: top;
    width: 180px;
    margin-left: 30px;
    position: relative;
}
 
.modal_user_content .ava{
  width: 180px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.modal_user_content .ava .img{
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 10px;
    border-radius: 100%;
}


.modal_user_content .btn_black {
    background-color: #1B1C1D;
    color: #FFFFFF;
    text-shadow: none;
}

.modal_user_content .save_profile_btn{
    background-color: #21BA45;
    color: #FFFFFF;
    text-shadow: none;
}

.modal_user_content .settings_description .row{
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.modal_user_content .settings_description .label{
    display: inline-block;
    min-width: 280px;
}

.modal_user_content .settings_description .field_input{
    display: inline-block;
    width: 100%;
}

.modal_user_content .settings_description .field_input input{
    width: 100%;
}



@container pb (max-width: 768px) {

  .modal_user_content .ava{
    justify-content: center;
    margin: 0 auto;
  }
  .content_profile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .modal_user_content .settings_avatar{

      margin-left: auto;
      margin-right: auto;
  }

  .modal_user_content .settings_description{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 20px;
  }

  .modal_user_content .settings_description .row{
    display: flex;
    flex-direction: column;
  }

}


</style>
