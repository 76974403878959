<template>


  <div class="pb grid_width">
  


    <template  v-if="useAuth().isAuth()">
      <div class="header_row">
        <h1>
          {{ $t('grow_questions_view_ask') }}      
        </h1>  
      </div>
  
      <QuestionShortForm/> 
      
      <br>
    </template>


    <div class="headerow"> 

      <h1>
        {{ $t('grow_questions_view_title') }}      
      </h1> 

      <GeneralSearchKeyword
        placeholder="Search Breeders & Nutrients"
        @choose="chooseTagKeyword"
      /> 



      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'create', name: $t('universal_sort_last_update')},      
          {id:'oldest', name: 'Oldest'},                  
          {id:'popular', name: 'Most popular'},      
        ]"
        />


      <GeneralSearchTags 
        :tags="tags" 
        :tagsselected="tags_selected" 
        :tagsdefault="tags_default" 
        :tagscount="grow_questions_data?.items_tags"    
        :tag-as-link="true"
        :tag-link-params="true"
        :tag-link="'/grow-questions'"
        :modify-path="true"
        :is-show-counts="false"
        @choose="chooseTags"
        @unchoose="unchooseTags"
        />


    </div>

    <br>

    

    <SkPhotos v-if="is_loading && start == 0" />


    <masonry-wall 
      v-else-if="grow_questions_data?.items?.length"
      :items="grow_questions_data.items" 
      :ssr-columns="1" :column-width="300" :gap="16"
      >
      <template #default="{ item, index }">


       <AdvCpm    
        v-if="item?.is_cpm"
        :key="index"
        :data="item"
        />          
       <QuestionBox              
          v-else-if="item?.id"
          :question="item"
          />   
      </template>
    </masonry-wall>

    <UiEmpty
      v-else
      />
 

    <ClientOnly>        
      <InfinityScroll 
        v-if="grow_questions_data?.items?.length >= limit"
        :is-loading="is_loading"
        ref="elInfinityScroll"/>
    </ClientOnly>

    

  </div>
</template>



<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute();
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $adv, $tagsUtil, $ga, $patcher} = useNuxtApp()
const { t } = useI18n()
const elInfinityScroll = ref(null)
const sort = ref('create')
const tags = ref($tagsUtil.tagsQuestions)
const tags_selected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tags_default = ref($tagsUtil.tagsQuestions)



const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

    
const chooseTagKeyword = function(tag){     
  if(tags_selected.value.indexOf('all') != -1)
    tags_selected.value = [];
  // tags.value[tag.tag] = tag;
  tags_selected.value.push(tag.tag)  
  clearPagination();        

  // console.log(tag);
  // $ga.searchFullTextExplore($tagsUtil.getTagsKeyword(tags.value, tags_selected.value));
} 


const unchooseTags = function(selected){  
  // console.log('unchooseTags');
  // console.log(selected);
  // console.log(tags_selected.value);
  tags_selected.value = tags_selected.value.filter((item) => item != selected);
  // console.log(tags_selected.value);
}        
const chooseTags = function(selected){          
  tags_selected.value.push(selected);      
  clearPagination();    
  // $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(tags.value, tags_selected.value));
}


const loadData = async function() {   

  // console.log('tags_selected');
  // console.log(tags_selected);
  // alert(tags_selected.value);
  // alert($tagsUtil.getTagsSimple(tags.value, tags_selected.value));
  
  const response = await $api.getQuestions({
    start: start.value,
    limit: limit.value,
    sortable: sort.value,
    q: $tagsUtil.getTagsKeyword(tags.value, tags_selected.value),
    tagsall: $tagsUtil.getTagsKeys(tags.value),
    tags: $tagsUtil.getTagsSimple(tags.value, tags_selected.value)
  })  

  if(response.items.length == limit.value){
    if(import.meta.client){
      var cpmData = useAdvCpm().getNext();
      if(cpmData) cpmData.is_cpm  = true;
      // console.log('cpmData');
      // console.log(cpmData);
      response.items.push(cpmData)
    }
  }
    
  return response;  
}



const { pending: is_loading,  data: grow_questions_data } = await useLazyAsyncData('grow_questions_data', async () => await loadData())

// console.log('!!!!!!!!!!!!!!!!!');
// console.log(grow_questions_data.value);

watchArray([start, sort, tags_selected], async ([new_start, new_sort, new_tags_selected], added, removed) => {
  // console.log('watcher');
  
  $patcher.change('/grow-questions', {
    isOnlyQuery: true,
    tags: new_tags_selected,
    sort: new_sort,
  });

  // alert(new_tags_selected.join(', '))

  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  


  if(new_start == 0){
    if(!grow_questions_data.value) grow_questions_data.value = {};
    
    grow_questions_data.value.items = [];
    isEnded.value = false;
  }

  grow_questions_data.value.items = [...grow_questions_data.value.items, ...dt.items]
  if(dt.items_tags?.length) grow_questions_data.value.items_tags = dt.items_tags;
  is_loading.value = false;

  // $adv.apply();
  
  useHead(useNuxtApp().$head.getGrowQuestions( ($tagsUtil.getTagsSimpleTrKey(tags.value, tags_selected.value).map((x) => t(x))).join(', ') ));

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      // console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

useHead(useNuxtApp().$head.getGrowQuestions( ($tagsUtil.getTagsSimpleTrKey(tags.value, tags_selected.value).map((x) => t(x))).join(', ') ));




 
</script>






<style scoped>

h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}


/*  */

.masonry {
 column-count: 3;
 column-gap: 15px;
}
.masonry > div {
 display: inline-block;
 width: 100%;
}
.sortb{
  margin-left: auto;
    align-self: baseline;
    margin-right: 0;
    display: flex;
    justify-self: flex-end;
    width: -moz-fit-content;
    width: fit-content;
}
@container pb (max-width: 800px) {
  .masonry  {
    column-count: 2;
  }
}

@container pb (max-width: 500px) {
  .masonry  {
    column-count: 1;
  }
}

</style>