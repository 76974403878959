<template>
 
  <div :class="{'page': true, 'gloading': isLoading}"> 
    
    <!-- <div class="avaf">
      <img class="ava" @click="avatarRandHandler" :src="'/avatars/' + avatar_num + '.jpg'">
      <div class="btn gray btn_rnd" @click="avatarRandHandler" type="is-primary"><i class="icon-shuffle"></i> {{ $t('signup_view_avatar_random') }}</div>
    </div> -->


    <UserAvatarSelector 
      @update:file="onAvatarFileUpdate" 
      @update:num="onAvatarNumUpdate"
      />

    <div class="fields">
    
      <UiInput
        :label="$t('signup_view_username')"
        :text="name"
        :name="'nick'"
        :error="errorName"
        @change:text="name = $event"
        @keyup.enter="signUpHandler"
        @blur="trySignup"
        />

      <UiInput
        :label="$t('signup_view_email')"
        :text="email"
        :name="'email'"
        :error="errorEmail"
        @change:text="email = $event"
        @keyup.enter="signUpHandler"
        @blur="trySignup"
        />

      <UiInput
        :label="$t('signup_view_password')"
        :text="password"
        type="password"
        autocomplete="new-password"
        :name="'password'"
        @change:text="password = $event"
        @keyup.enter="signUpHandler"
        @focus="showPasswordCheck = true"
        @blur="showPasswordCheck = false"
        ref="elPassword"
        />

      <ClientOnly>
        <UiTooltip 
          :target-el="elPassword" 
          preferred-placement="bottom" 
          :max-width="300"
          :isOpen="showPasswordCheck"
          :show-on-focus="false"
          >
          <UiPasswordCheck 
            :password="password" />
        </UiTooltip>
      </ClientOnly>

      <UiInput
        :label="$t('signup_view_password2')"
        :text="password_repeat"
        type="password"
        :name="'password2'"
        :error="errorPassword2"
        @change:text="password_repeat = $event"
        @keyup.enter="signUpHandler"
        @blur="trySignup"
        />


        <NuxtTurnstile  ref="turnstile" v-model="token" />

    </div>

    
<!--  
    <div class="acts"> 

      <UiButton
        :name="$t('universal_button_signup')"
        :type="'primary'"
        @click="signUpHandler"
        :is-loading="isLoading"
        />        

      <UiButton
        :name="$t('login_view_login_button')"
        :tag="'NuxtLink'"
        :type="'link'"
        to="/auth/signin"
        />     

      <UiButton
        :name="$t('universal_button_cancel')"
        :tag="'NuxtLink'"
        :type="'link'"
        to="/"
        />     
 

    </div>  
     -->
  </div>  
  
</template>





<script setup>

const props = defineProps({
  signupStartAuth: {
    type: Boolean
  }, 
});

const { t } = useI18n();
const router = useRouter();

const token = ref(null)
const { $api, $refs, $ga, $popup } = useNuxtApp()
const { login, setSession,  signIn } = useAuth()
const isLoading = ref(false)
const turnstile = ref()

const showPasswordCheck = ref(false)
const elPassword = ref(null)

const selectedAvatar = ref(null); // Holds avatar data from AvatarSelector

const onAvatarFileUpdate = (file) => {
  selectedAvatar.value = file;
};
const onAvatarNumUpdate = (num) => {
  avatar_num.value = num;
};

// recaptcha
// import { useReCaptcha } from 'vue-recaptcha-v3';
// const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
// const recaptchaInstance = useReCaptcha();
const emits = defineEmits(['filled']);

// const execute = async function() {
//   await recaptchaInstance?.recaptchaLoaded();
//   const token = await recaptchaInstance?.executeRecaptcha('signin');
//   return token
// }
// 


const name = ref('')
const email = ref(router.currentRoute?.value.query?.email || '')
const password = ref('')
const password_repeat = ref('')
const avatar_num = ref(Math.floor(Math.random() * 83) + 1)

const readySignup = ref(props.signupStartAuth || false)
const errorName = ref('')
const errorEmail = ref('')
const errorPassword = ref('')
const errorPassword2 = ref('')




const validateEmpty = function() {   


  var ret = true;

  if(!name.value) {    
    errorName.value = 'Empty field';
    ret = ret && false;
  }else{
    errorName.value = '';
  }

  if(!email.value) {    
    errorEmail.value = 'Empty field';
    ret = ret && false;
  }else{
    errorEmail.value = '';
  }

  if(!password.value) {    
    errorPassword.value = 'Empty field';
    ret = ret && false;
  }else{
    errorPassword.value = '';
  }

  if(!password_repeat.value) {    
    errorPassword2.value = 'Empty field';
    ret = ret && false;
  }else{
    errorPassword2.value = '';
  }


  console.log('validateempty');
  console.log(ret);

  return ret;
} 

const validate = function() {     
   

  var ret = true;

  var pattern = /^([a-zA-Z0-9_])+$/i;
  if(name.value)
  if(!pattern.test(name.value) || name.value.length < 5 || name.value.length > 20){ 
    errorName.value = t('signup_view_error_username_length');
    ret = ret && false;
  }else{
    errorName.value = '';
  }

  var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,6}$/i;
  if(email.value)
  if(!pattern.test(email.value)){ 
    errorEmail.value = t('signup_view_error_email_format');
    ret = ret && false;
  }else{
    errorEmail.value = '';
  }

  if(password.value)
  if(!useAuth().isStrongPassword(password.value)){
    errorPassword.value = t('signup_view_error_password_length');
    ret = ret && false;
  }else{
    errorPassword.value = '';
  }

 


  if(password_repeat.value)
  if(password.value != password_repeat.value){
    errorPassword2.value = t('signup_view_error_password_match');
    ret = ret && false;
  }else{
    errorPassword2.value = '';
  }


  console.log('validate');
  console.log(ret);

  return ret;
}


const avatarRandHandler = function() {
  avatar_num.value = Math.floor(Math.random() * 83) + 1;
}


const trySignup = async function() {
  if(validate()){
    emits('filled');
  }
  // if(!name.value || !email.value || !password.value || !password_repeat.value) {    
  //   return false;
  // }
  // return true;
}


const uploadAvatar = async (id) => { 
  if(!selectedAvatar.value?.file) return; 

  let formData = new FormData();  
  formData.append('file', selectedAvatar.value.file, selectedAvatar.value.file.name);       


  try {
    await $api.postGrowersAvatars(id, formData) 
  } catch (error) {
    console.error('Upload avatar error', error);
  }
}


const signUpHandler = async function() {
  console.log('signUpHandler');

  if(!validateEmpty()) return false;
  if(!validate()) return false;

  console.log('start signup');
  
  isLoading.value = true;

  try {
    const responseSignUp = await $api.postGrowers({ 
      name: name.value, 
      email: email.value, 
      password: password.value, 
      password_repeat: password_repeat.value, 
      avatar_num: selectedAvatar.value?.file ? null : avatar_num.value, 
      recaptcha: token.value
    });

    const userId = responseSignUp.data.value.data.id;
    await setSession(responseSignUp.data.value.data.token);

    await uploadAvatar(userId);
    useAuth().refresh();
    $ga.addGrower();

    isLoading.value = false;
    return true;

  } catch (error) {
    turnstile.value?.reset();
    isLoading.value = false; 
    $popup.error(error);
    return false;
  }
};

const signUpHandler2 = async function() {

  var ret = false;
  console.log('signUpHandler');

  if(!validateEmpty()) return false;
  if(!validate()) return false;
  
  console.log('start signup');
  
  // var recaptcha = await execute()

  isLoading.value = true;

  var userId = null;


  await $api.postGrowers({ 
    name: name.value, 
    email: email.value, 
    password: password.value, 
    password_repeat: password_repeat.value, 
    avatar_num: selectedAvatar.value?.file ? null : avatar_num.value, 
    recaptcha: token.value
  })
  .then(responseSignUp => { 

    ret = true;
    userId = responseSignUp.data.value.data.id;

    isLoading.value = false;  
    setSession(responseSignUp.data.value.data.token);
    // $popup.success('Success');
    // router.push('/');

    $ga.addGrower();
 
  })
  .catch((error) => {
    turnstile.value?.reset();
    isLoading.value = false; 
    $popup.error(error);
  });


  if(ret){
    await uploadAvatar(userId);

    useAuth().refresh();
  }


  return ret
  
  
}

defineExpose({ signUpHandler });

// watch(() => readySignup, (new_readySignup) => {
//   // if(readySignup){
//     // alert(1);
//     console.log('readySignup');
//     console.log(new_readySignup);
//   // }
// }, { immediate: true })
 



</script>





 


<style scoped>

.page{
  display: flex;
  align-items: center;
  text-align: center;
  gap: 1rem;
  position: relative;
}
.ava{
  max-width: 180px;
  border-radius: 100%;
  display: block;
  cursor: pointer;
}
.fields{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.fields > div{
  width: calc(50% - 0.5rem);
}
.fields .inp{
  text-align: left;
}
.avaf{
  margin-bottom: 1rem;
  text-align: center;
}
label{
  text-align: left;
}
.btn_rnd{
  display: block;
  z-index: 1;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-top: -28px;
}
.acts{
  display: flex;
  justify-content: center;
}


@container pb (max-width: 600px) {
  .page{
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .fields{
    gap: 0.5rem;
  }
  .fields > div{
    width: 100%;
  }
}
</style>
