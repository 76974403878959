<template>
  <div class="pbf grid_width" v-if="activity_data">
 

    <div class="proslist">


      <SkBasicRows 
        v-if="is_loading && !following_data?.items_following?.length"
        />
 
      <template v-else v-for="pro in activity_data">
        <div class="last_update" v-if="pro.show_last_update">
          <i class="icon minus"></i> {{ $dayjs.utc(pro.last_update).fromNow() }} <i class="icon minus"></i>
        </div>
        <UserActivityItem
          :data="pro"
          />
      </template>

      <ClientOnly>        
        <InfinityScroll 
          v-if="!isEnded"
          :is-loading="is_loading"
          ref="elInfinityScroll"/>
      </ClientOnly>

    </div>
  

  </div>
</template>

<script setup lang="ts">

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const { $api } = useNuxtApp();


interface Props {
  userId: number;
  inurl: string;
}

const props = defineProps<Props>();
 

const elInfinityScroll = ref(null)
const url = ref(props.inurl);
const start = ref(0);
const limit = ref(20);
const list = ref([]);
const last_update = ref('');
const isEnded = ref(false);

const clearPage = () => {
  start.value = 0;
  list.value = [];
}

const infiniteHandler = (state) => {
  start.value += limit.value;
  loadResults(state);
}



const loadData = async function() {  
  const response = await $api.getGrowerActivity(props.userId, {
    start: start.value,
    limit: limit.value,
  });
  return response;  
}



const { pending: is_loading,  data: activity_data } = await useLazyAsyncData('activity_data', async () => await loadData())


watchArray([start], async ([new_start], added, removed) => {


  if(new_start == 0){
    activity_data.value = [];
    isEnded.value = false;
  }

  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();
    
  if(dt.length == 0)
    isEnded.value = true;  

  activity_data.value = [...activity_data.value, ...dt]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})


useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)





</script>

<style scoped>

 

.proslist {

}


.proslist.centered {
}

.proslist > .last_update{
    text-align: center;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    color: #cacaca;
    font-weight: bold;
    margin-top: 20px;
}

</style>
