<template>


  <div class="similar"  v-if="problemData?.id && similar_problem_data?.items?.length">

    <div class="divider">
      {{ $t('grow_questions_similar_questions') }}            
    </div>
 

    <masonry-wall 
      v-if="similar_problem_data?.items?.length"
      :items="similar_problem_data.items" 
      :ssr-columns="1" :column-width="300" :gap="16"
      >
      <template #default="{ item, index }">
       <QuestionBox    
          :question="item"
          />   
      </template>
    </masonry-wall>
 
  </div>
  
</template>

<script setup>

const { $api }  = useNuxtApp();
 
const start = ref(0)
const limit = ref(20)

const props = defineProps({
  problemData: {
    type: Object
  }, 
})



const getTags = () => {
  var list = props.problemData.items_problem_symptom.map(item => {
    return 'symptom_' + item.id
  })
  
  list.push('closed');
  list.push('rand' + props.problemData?.id);
  return list.join(',')
}

const loadData = async () => {
  
  const response = await $api.getQuestions({
    start: start.value,
    limit: limit.value,
    sortable: 'random',
    tags: getTags()
  })  
  return response;  
}


const { pending: is_loading,  data: similar_problem_data, refresh: refreshData } = await useLazyAsyncData('similar_problem_data', async () => await loadData())


watch(() => props.problemData.id, async () => {
  console.log('refresh data similar');

  similar_problem_data.value = null;
  refreshData();
}, { deep: true })

 
</script>

<style scoped>
 
 .similar{
  margin-bottom: 2rem;
 }
.report_boxs{
  justify-content: center!important;
  max-height: 210px;
  overflow: hidden;
}



</style>
