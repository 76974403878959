<template>
  <ClientOnly>
  <Teleport to="#teleport">
    <div 
      v-show="visible" 
      class="tooltip-container" 
      :style="tooltipStyle" 
      ref="tooltipRef"
      @mousedown.stop
    >
      <div class="tooltip-content">
        <slot />
      </div>
      <div class="tooltip-arrow" :class="arrowClass"></div>
    </div>
  </Teleport>
</ClientOnly>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, nextTick, computed } from 'vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  showOnFocus: {
    type: Boolean,
    default: true
  },
  preferredPlacement: {
    type: String,
    default: 'top',
    validator: (val) => ['top', 'bottom'].includes(val)
  },
  maxWidth: {
    type: Number,
    default: 200
  },
  targetEl: {
    type: [HTMLElement, Object],
    required: false
  }
})

const tooltipRef = ref(null)
const visible = ref(false)
const placement = ref(props.preferredPlacement)

let targetElement = null
let observer = null

const updatePosition = () => {
  if (!targetElement || !tooltipRef.value) return

  const targetRect = targetElement.getBoundingClientRect()
  const tooltip = tooltipRef.value
  tooltip.style.visibility = 'hidden'
  tooltip.style.left = '-9999px'
  tooltip.style.top = '-9999px'

  nextTick(() => {
    const tooltipRect = tooltip.getBoundingClientRect()

    let topPosition = 0
    let leftPosition = targetRect.left + window.scrollX + (targetRect.width / 2) - (tooltipRect.width / 2)


    if (placement.value === 'top') {
      topPosition = targetRect.top + window.scrollY - tooltipRect.height - 10 
      if (topPosition < 0) {
        placement.value = 'bottom'
        topPosition = targetRect.bottom + window.scrollY + 10
      }
    } else {
      topPosition = targetRect.bottom + window.scrollY + 10
      const bottomSpace = window.innerHeight - (targetRect.bottom + 10 + tooltipRect.height)
      if (bottomSpace < 0) {
        placement.value = 'top'
        topPosition = targetRect.top + window.scrollY - tooltipRect.height - 10
      }
    }

    tooltip.style.left = leftPosition + 'px'
    tooltip.style.top = topPosition + 'px'
    tooltip.style.visibility = 'visible'
    tooltip.style.opacity = 1
  })
}


onMounted(() => {
  targetElement = (props.targetEl?.$el) ? props.targetEl.$el : props.targetEl
  if (!targetElement) return

  if (props.showOnFocus) {
    targetElement.addEventListener('focus', onFocus)
    targetElement.addEventListener('blur', onBlur)
    targetElement.addEventListener('mouseenter', onMouseEnter)
    targetElement.addEventListener('mouseleave', onMouseLeave)
  }


  window.addEventListener('scroll', updatePosition, true)
  window.addEventListener('resize', updatePosition, true)


  watch(() => props.isOpen, (newVal) => {

    visible.value = newVal
    if (newVal) {
      placement.value = props.preferredPlacement
      updatePosition()
    }
  }, { immediate: true })
})

onBeforeUnmount(() => {
  if (!targetElement) return
  if (props.showOnFocus) {
    targetElement.removeEventListener('focus', onFocus)
    targetElement.removeEventListener('blur', onBlur)
    targetElement.removeEventListener('mouseenter', onMouseEnter)
    targetElement.removeEventListener('mouseleave', onMouseLeave)
  }

  window.removeEventListener('scroll', updatePosition, true)
  window.removeEventListener('resize', updatePosition, true)
})

const onFocus = () => {
  if (!props.isOpen && props.showOnFocus) {
    visible.value = true
    placement.value = props.preferredPlacement
    updatePosition()
  }
}
const onBlur = () => {
  if (!props.isOpen && props.showOnFocus) {
    visible.value = false
  }
}

const onMouseEnter = () => {
  if (!props.isOpen && props.showOnFocus) {
    visible.value = true
    placement.value = props.preferredPlacement
    updatePosition()
  }
}

const onMouseLeave = () => {
  if (!props.isOpen && props.showOnFocus) {
    visible.value = false
  }
}

const tooltipStyle = computed(() => ({
  position: 'absolute',
  zIndex: '9999',
  maxWidth: props.maxWidth + 'px'
}))

const arrowClass = computed(() => {
  return {
    'top-arrow': placement.value === 'bottom',
    'bottom-arrow': placement.value === 'top'
  }
})
</script>

<style scoped>
.tooltip-container {
  visibility: hidden; 
  box-shadow: 0 3px 6px rgba(25, 25, 25, .16);
  transition: opacity 0.3s;
  opacity: 0;
}

.tooltip-content {
  background-color: var(--un-background-color-gray);
  color: #fff;
  padding: 0.8rem;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.tooltip-arrow {
  width: 0;
  height: 0;
  border: solid transparent;
  position: absolute;
}

.bottom-arrow {
  border-width: 6px 6px 0 6px;
  border-color: var(--un-background-color-gray) transparent transparent transparent;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.top-arrow {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent var(--un-background-color-gray) transparent;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
}
</style>