<template>
 
  
  <div class="page">
    


    <form method="post" autocomplete="on">

        
      <UiInput
        :label="$t('login_view_email')"
        :text="name"
        :name="'email'"
        :id="'email'"
        :autocomplete="'email'"
        @keyup.enter="mySignInHandler"
        @change:text="name = $event"
        />

      <UiInput
        :label="$t('login_view_password')"
        :text="password"
        type="password"
        :name="'password'"
        :id="'password'"
        :autocomplete="'current-password'"
        @keyup.enter="mySignInHandler"
        @change:text="password = $event"
        />


        <!-- <div @click="reset">reset</div> -->

      <NuxtTurnstile ref="turnstile" v-model="token" />



      <div class="actions"> 


        <UiButton
          :name="$t('login_view_login_button')"
          :type="'primary'"
          @click="mySignInHandler"
          :is-loading="isLoading"
          />        

        <UiButton
          :name="$t('universal_button_signup')"
          :tag="'NuxtLink'"
          :type="'link'"
          to="/auth/signup"
          />     
          

        <UiButton
          :name="$t('login_view_forgot_password')"
          :tag="'NuxtLink'"
          :type="'link'"
          to="/auth/forgot-password"
          />     

      </div>  

    </form>

    <!-- <div class="divider">{{ $t('login_view_or') }}</div> -->

    
  </div>  

</template>


<script setup>

// import Vue from 'vue'

definePageMeta({
  layout: "short",
  auth: false
})

const { t } = useI18n();
const name = ref('')
const password = ref('')
const token = ref(null)
const router = useRouter();
const isLoading = ref(false);
const backPath = ref(useAuth().getBackLoginPath());
const { $user, $popup, $ga } = useNuxtApp();
const turnstile = ref()

const { login, isLoggedIn } = useAuth()

// recaptcha
// import { useReCaptcha } from 'vue-recaptcha-v3';

// Vue.use(VueReCaptcha, { siteKey: "6Ldh-EAeAAAAAKo8vixoyNGuR2qg-vU6Jh20B5py" });


// const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
// const recaptchaInstance = useReCaptcha();
// const execute = async function() {
//   await recaptchaInstance?.recaptchaLoaded();
//   const token = await recaptchaInstance?.executeRecaptcha('signin');
//   return token
// }
// 

const validate = function() {     
  
  if(!token.value) {    
    $popup.error('Waiting captcha to be filled or refresh page');
    return false;
  }
  
  if(!name.value || !password.value) {    
    $popup.error('Empty fields');
    return false;
  }

  return true;
}

const mySignInHandler = async function() {


  if(!validate()) return false;
    
  // var recaptcha = await execute()

  isLoading.value = true;

  login({ 
    name: name.value, 
    password: password.value,
    recaptcha: token.value
  })
  .then(response => { 
    
    isLoading.value = false;
    $popup.success('Success');

    
    router.push(backPath.value ?? '/');
    $ga.eventGA('user_login');
  })
  .catch((response, error) => {
    turnstile.value?.reset();
    isLoading.value = false;
    $popup.error(t('login_view_error_invalid_credentials'));
  });
  
}


// onMounted(() => {
//   $recaptcha.init()
// })

// onBeforeUnmount(() => {
//   $recaptcha.destroy()
// })

</script>

 

<style scoped>
.page{
  padding: 1rem;
  text-align: center;
} 
.page > *{
  margin-bottom: 0.5rem;
}
.actions{
  /* margin-top: 10px; */
}
.actions > *{
  margin-right: 10px;
}

</style>
